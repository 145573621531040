import React, { useState } from "react"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WindMillImg from "../images/media.jpg"
import PageHeader from "../partialPages/PageHeader"
import { Container, Row, Col, Alert } from "react-bootstrap"

const page = {
  name: "Get started",
  pageDesc:
    "Get in touch to learn more about how Vulcan AI can partner with you for Industry 4.0.",
}
const Contact = props => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [company, setCompany] = useState("")
  const [enquiryType, setEnquiryType] = useState("")
  const [query, setQuery] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(false)
  const [submittingFlag, setSubmitFlag] = useState(false)
  const handleSubmit = evt => {
    evt.preventDefault()
    // Axioos
    setSubmitFlag(true)

    axios
      .post(
        "https://vulcanwebcontact.azurewebsites.net/api/vulcancontanctus?code=a6fehtGuyV2ONJYAZnaN2TfMIbv2gQar0pvFIrGCwWv1dOY8qexfzA==",
        { name, email, phone, country, company, enquiryType, query }
      )
      .then(res => {
        setShowSuccess(true)
        setShowError(false)
        setSubmitMessage(res.data)
        setSubmitFlag(false)
      })
      .catch(err => {
        setShowError(true)
        setShowSuccess(false)
        setSubmitMessage(err.data)
        setSubmitFlag(false)
      })
  }
  const mainContent = (
    <>
      {" "}
      <form action="" id="contactForm" onSubmit={handleSubmit}>
        <Row>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="name">
                Name<span className="required-aestrisk">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="email">
                Work Email <span className="required-aestrisk">*</span>
              </label>

              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="tel">
                Phone<span className="required-aestrisk">*</span>
              </label>

              <input
                type="tel"
                name="tel"
                id="tel"
                className="form-control"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                pattern="+[0-9]{2}[0-9]{10}"
                required
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="coutnry">
                Country<span className="required-aestrisk">*</span>
              </label>
              <input
                type="text"
                name="coutnry"
                id="coutnry"
                value={country}
                onChange={e => setCountry(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="comapny">
                Company<span className="required-aestrisk">*</span>
              </label>
              <input
                type="text"
                name="comapny"
                id="comapny"
                value={company}
                onChange={e => setCompany(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="enquiry">
                Type of Enquiry<span className="required-aestrisk">*</span>
              </label>
              <select
                name="enquiry"
                className="form-control"
                value={enquiryType}
                onChange={e => setEnquiryType(e.target.value)}
                required
              >
                <option value="">Select...</option>
                <option value="WorkSafe">WorkSafe</option>
                <option value="TrackSafe">TrackSafe</option>
                <option value="Ceres">Ceres</option>

                <option value="AI Applications">AI Applications</option>
                <option value="Industries">Industries</option>
                <option value="Careers">Careers</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="form-group  pb-2">
              <label htmlFor="note">
                How can we help?<span className="required-aestrisk">*</span>
              </label>
              <textarea
                type="text"
                name="note"
                id="note"
                value={query}
                onChange={e => setQuery(e.target.value)}
                className="form-control"
              ></textarea>
            </div>
          </Col>
        </Row>
        <div className="text-center">
          {!submittingFlag ? (
            <input
              type="submit"
              style={{ width: "135px" }}
              value="Contact Us"
              className="btn btn-outline-primary nohover text-bold text-primary apply-button mt-4"
            />
          ) : (
            <button
              className="btn btn-outline-primary mt-4"
              type="button"
              style={{ width: "135px" }}
            >
              Submitting{" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </div>
      </form>
      <Alert show={showSuccess} variant="success" className="mt-4 text-center">
        <h5 className="m-auto">
          {submitMessage || "Contact information submitted."}
        </h5>
      </Alert>
      <Alert show={showError} variant="danger" className="mt-4 text-center">
        <h4 className="m-auto">{submitMessage || "Error occured"}</h4>
      </Alert>
    </>
  )
  return (
    <Layout pageInfo={{ pageName: "contact" }} hideTimeline={true}>
      <SEO title={page.name} description={page.pageDesc} />

      <PageHeader
        bgImg={WindMillImg}
        pgTitle={page.name}
        pgDesc={page.pageDesc}
        titleClass="text-center"
        pClass="text-center"
        overlayOverRide={{ background: "rgba(0, 0, 0, 0.6)" }}
      ></PageHeader>

      <section id="contact-form" className="py-5 ">
        <Container className="d-none d-lg-block">{mainContent}</Container>
        <div className="px-4 d-block d-lg-none">{mainContent}</div>
      </section>
    </Layout>
  )
}
export default Contact
